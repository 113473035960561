<template>
  <div class="pt-12 md:pt-32 max-w-8xl mx-auto">
    <div class="md:flex w-full px-6 xl:px-12">
      <div class="md:w-1/2 container">
        <p class="text-3xl md:text-5xl xl:text-7xl fontRg font-bold">
          {{ tilte }}
        </p>
      </div>
      <div class="md:w-1/2 text-center container">
        <p class="text-xl md:text-3xl fontLt text-justify">
                   {{ description }}

        </p>
      </div>
    </div>
    <div class="justify-start px-6 xl:px-12 mt-6 md:mt-16">
      <img :src="image" alt="" />
    </div>

    <div class="lg:flex w-full">
      <div class="lg:w-1/2 container px-6 xl:px-12 py-6 md:py-16">
        <p class="text-3xl md:text-5xl xl:text-7xl fontRg font-bold">
          {{ $t("Contact.lociton") }}
        </p>
        <p class="text-3xl md:text-5xl xl:text-7xl fontRg font-bold">
          <digital-clock :blink="false" :displaySeconds="true" :twelveHour	="false" />
        </p>
      </div>
    </div>

    <div class="lg:flex w-full">
      <div class="lg:w-1/2 text-center container px-6 xl:px-12 py-2">
        <p class="text-xl md:text-3xl fontLt text-justify">hello@hroof.ly</p>
        <p class="text-2xl lg:text-4xl fontLt text-justify">
          {{ $t("Contact.phone") }}
        </p>
      </div>
      <div
        class="lg:w-1/2 text-center py-2"
        :class="`${$i18n.locale}` == 'ar' ? 'md:ml-32 px-6' : 'px-6 md:mr-32'"
      >
        <p class="text-lg md:text-2xl lg:text-4xl fontLt text-justify">
                   {{ section2description1Right }}

        </p>
      </div>
    </div>

    <div class="md:flex w-full">
      <div class="md:w-1/2 container px-6 xl:px-12 py-8">
        <p class="text-2xl lg:text-4xl fontLt">{{ $t("Contact.street") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DigitalClock from "vue-digital-clock";
export default {
   components: {
    DigitalClock
  },
 
  mounted() {
       this.Getdata();
    window.scrollTo(1, 1);
  },
    data() {
    return {
      tilte: '',
      description:'',
      section2description1Right:'',
       Local:this.$i18n.locale,
       image:'',
      // carts : JSON.parse(localStorage.getItem("cart")),
  
    };
  },
   methods: {
    Getdata() {
        this.$http.contactService.GetContact(this.Local)
        .then((res) => {
          this.tilte=res.data.Title,
          this.description=res.data.Description
          this.section2description1Right=res.data.Section2description1Right
          this.image=`http://hroof.germanywestcentral.azurecontainer.io:1337${res.data.image[0].url}`
           // this.addSuccessed = res.data.tilte;
  
         })
        .catch((err) => {
          this.addErorr = err;
        });
    },
  },
};

</script>

<style scoped>
span {
  white-space: nowrap;
  font-family: "AktivGrotesk_Trial_Rg";
  font-size: 80px;
  color: #000;
  font-weight: bold;
  text-decoration: none;
}
</style>
